import { DATA_POINTS_1, DATA_POINTS_2 } from '../watchlists';

const DEFAULT = {
    STOCK_SYMBOL: '0P000000GY',
    FUND_SYMBOL: 'FOUSA00BKS',
    COLUMN_SET_BEHAVIOUR: 'merge'
};

const INDEX_COLUMN_SETS = [
    {
        id: 'basic',
        dataPoints: [
            'name',
            'symbol',
            'lastPrice',
            'chg',
            'chg%',
            'highPrice',
            'lowPrice',
            'tradeDate',
            'tradeTime'
        ]
    }
];

const components = [
    {
        componentName: 'markets-components-svgchart',
        componentType: 'markets-components-svgchart',
        componentState: {
            mainTicker: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'mwc-markets-commodity',
        componentType: 'mwc-markets-commodity',
        componentState: {}
    },
    // {
    //     componentName: 'mwc-markets-regulatory',
    //     componentType: 'mwc-markets-regulatory',
    //     componentState: {
    //         symbol: DEFAULT.STOCK_SYMBOL
    //     }
    // },
    {
        componentName: 'mwc-markets-gla',
        componentType: 'mwc-markets-gla',
        componentState: {
            gridHover: {
                menu: []
            }
        }
    },
    {
        componentName: 'mwc-markets-research',
        componentType: 'mwc-markets-research',
        componentState: {
            symbol: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'mwc-markets-sector-heatmap',
        componentType: 'mwc-markets-sector-heatmap',
        componentState: {
            showOneDayAsIntraday: true
        }
    },
    {
        componentName: 'mwc-markets-treasury',
        componentType: 'mwc-markets-treasury',
        componentState: {}
    },
    {
        componentName: 'mwc-markets-gmb',
        componentType: 'mwc-markets-gmb',
        componentState: {
            salBundleHost: 'lib/'
        }
    },
    {
        componentName: 'mwc-markets-barometer',
        componentType: 'mwc-markets-barometer'
    },
    {
        componentName: 'mwc-markets-forex',
        componentType: 'mwc-markets-forex',
        componentState: {}
    },
    {
        componentName: 'mwc-markets-cryptocurrency',
        componentType: 'mwc-markets-cryptocurrency',
        componentState: {}
    },
    {
        componentName: 'mwc-markets-watchlist-market-index',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_1,
            gridHover: {
                menu: []
            }
        },
        title: 'Market Indexes Watchlist',
        groupName: 'Markets',
        type: 'component',
        showAutocomplete: false,
        showExportIcon: true,
        showSymbol: false,
        desc:
            'Market Indexes Watchlist allows you to access and analyze intraday and historical exchange data for regional indexes.'
    },
    {
        componentName: 'mwc-markets-watchlist-morningstar-index',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_2,
            gridHover: {
                menu: []
            },
            columnSets: INDEX_COLUMN_SETS,
            columnSetsBehaviour: DEFAULT.COLUMN_SET_BEHAVIOUR
        },
        showAutocomplete: false,
        showExportIcon: true,
        title: 'Morningstar Indexes Watchlist',
        groupName: 'Markets',
        type: 'component',
        showSymbol: false,
        desc:
            'Morningstar Indexes Watchlist allows you to access and analyze intraday and historical exchange data for Morningstar indexes.'
    },
    {
        componentName: 'mwc-markets-watchlist-morningstar-sector',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_2,
            gridHover: {
                menu: []
            },
            columnSets: INDEX_COLUMN_SETS,
            columnSetsBehaviour: DEFAULT.COLUMN_SET_BEHAVIOUR
        },
        title: 'Morningstar Sector Watchlist',
        groupName: 'Markets',
        showAutocomplete: false,
        showExportIcon: true,
        showSymbol: false,
        desc:
            'Morningstar Sector Watchlist allows you to access and analyze intraday and historical exchange market data for Morningstar Sectors.'
    },
    {
        componentName: 'mwc-markets-options',
        componentType: 'mwc-markets-options',
        componentState: {
            symbol: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'mwc-markets-quote',
        componentType: 'mwc-markets-quote',
        componentState: {
            symbol: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'mwc-markets-time-sales',
        componentType: 'mwc-markets-time-sales',
        componentState: {
            symbol: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'markets-components-minichart',
        componentType: 'markets-components-minichart',
        componentState: {
            frequency: '5',
            interval: '1D',
            mainTicker: DEFAULT.STOCK_SYMBOL
        }
    },
    {
        componentName: 'mwc-markets-watchlist-morningstar-government',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_2,
            gridHover: {
                menu: []
            },
            columnSets: INDEX_COLUMN_SETS,
            columnSetsBehaviour: DEFAULT.COLUMN_SET_BEHAVIOUR
        },
        title: 'U.S. Government Bond Watchlist',
        groupName: 'Markets',
        showAutocomplete: false,
        showExportIcon: true,
        showSymbol: false,
        desc:
            'U.S. Government Bond Watchlist allows you to access and analyze intraday and historical exchange market data for various maturities across the U.S. Government bond market.'
    },
    {
        componentName: 'mwc-markets-watchlist',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'custom',
        componentState: {
            removable: true,
            showExcelRTD: true,
            gridHover: {
                removable: true,
                menu: []
            },
            symbol: ''
        },
        isClosable: true,
        title: 'Watchlist',
        groupName: 'Markets',
        showSymbol: false,
        showExportIcon: true,
        showImportIcon: true,
        showAutocomplete: true,
        desc:
            'Watchlist allows user to access and analyze intraday and historical exchange data for their investments.'
    },
    {
        componentName: 'mwc-markets-watchlist-pre-market-futures',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            title: 'Pre-Market Futures',
            columnType: 'basic',
            defaultDataPoints: DATA_POINTS_2,
            columnSets: [
                {
                    id: 'basic',
                    dataPoints: [
                        'name',
                        'symbol',
                        'lastPrice',
                        'chg',
                        'chg%',
                        'openPrice',
                        'lowPrice',
                        'highPrice',
                        'tradeDate',
                        'tradeTime'
                    ]
                }
            ],
            columnSetsBehaviour: DEFAULT.COLUMN_SET_BEHAVIOUR,
            labels: {
                en: {
                    name: 'Market',
                    lastPrice: 'Cur Future'
                },
                fr: {
                    name: 'Marché',
                    lastPrice: 'Courant Futur'
                }
            }
        },
        isClosable: true,
        title: 'Pre-Market Futures',
        groupName: 'Markets',
        showSymbol: false,
        showSettingIcon: false,
        /* eslint-disable */
        desc:
            "Pre-Market Futures component provides an indication (but not a prediction) of how the stock market may open when trading commences. Pre-market futures are stock index futures evaluated prior to the start of the day's stock trading. Futures and stocks trade separately, but the price of the index relative to the value of index futures gives a strong hint regarding stock market early action."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-watchlist-portfolio-north-america',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'readonly',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_1,
            gridHover: {
                menu: []
            },
            holdingsId: 'readonly-1'
        },
        title: 'North America Portfolio',
        groupName: 'Markets',
        showSymbol: false,
        showExportIcon: true,
        showAutocomplete: true,
        desc:
            'Sample Watchlist to showcase tracking of securities in North America market. Watchlist component allows you to access and analyze intraday and historical exchange data.'
    },
    {
        componentName: 'mwc-markets-watchlist-portfolio-emea',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'readonly',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_1,
            gridHover: {
                menu: []
            },
            holdingsId: 'readonly-2'
        },
        title: 'EMEA Portfolio',
        groupName: 'Markets',
        showSymbol: false,
        showExportIcon: true,
        showAutocomplete: true,
        desc:
            'Sample Watchlist to showcase tracking of securities in EMEA market. Watchlist component allows you to access and analyze intraday and historical exchange data.'
    },
    {
        componentName: 'mwc-markets-watchlist-portfolio-asia',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'readonly',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_1,
            gridHover: {
                menu: []
            },
            holdingsId: 'readonly-3'
        },
        title: 'ASIA Portfolio',
        groupName: 'Markets',
        showSymbol: false,
        showExportIcon: true,
        showAutocomplete: true,
        desc:
            'Sample Watchlist to showcase tracking of securities in Asia market. Watchlist component allows you to access and analyze intraday and historical exchange data.'
    },
    {
        componentName: 'mwc-markets-news-investment',
        componentType: 'mwc-markets-news',
        componentState: {
            newsType: 'investment',
            newsTypeOptions: '',
            symbol: DEFAULT.STOCK_SYMBOL
        },
        title: 'Investment News',
        showSymbol: true,
        showAutocomplete: true,
        groupName: 'News',
        desc:
            'Investment News component allows you to monitor and read third-party news on the investment in focus. The news feed is updated automatically, and you can filter by specific providers.'
    },
    {
        componentName: 'mwc-markets-news-top-stories',
        componentType: 'mwc-markets-news',
        componentState: {
            newsTypeOptions: 'topStories,mostViewed',
            newsType: 'topStories'
        },
        title: 'Top Stories',
        showSymbol: false,
        showSettingIcon: false,
        groupName: 'News',
        desc:
            'Top Stories component allows you to stay up to date with intraday market updates from Benzinga News Desk. The articles are for the current day only and are published every few hours throughout the day at Mid-Morning, Mid-Day and Mid-Afternoon (with the “Market in 5 Minutes” article published each morning).'
    },
    {
        componentName: 'mwc-markets-news-daily-briefings',
        componentType: 'mwc-markets-news',
        componentState: {
            newsTypeOptions: '',
            newsType: 'dailyBriefings'
        },
        title: 'Daily Briefings',
        showSymbol: false,
        showSettingIcon: false,
        groupName: 'News',
        desc:
            'Daily briefings news component allows you to get daily briefings from Dowjones.'
    },
    {
        componentName: 'mwc-markets-news-us-markets',
        componentType: 'mwc-markets-news',
        componentState: {
            newsTypeOptions: 'usMarkets,mostViewed',
            showProviderSetting: true,
            usMarketsSourceCode:
                'AWRAWR,BZBZPR,BZBZMKT,BRIEFINGSTOCK_MARKET_UPDATE,BWIPREMBWIPREM,CANADANWCANADAPR,GLBNEWSDOTCOM,NFCNFC,PRNPREMPRNPREM,TNWSWRCACNPR____,ZACKSZIRGENERIC',
            newsType: 'usMarkets',
            frequency: 5000,
            symbol: DEFAULT.STOCK_SYMBOL
        },
        title: 'U.S. Market News',
        showSymbol: false,
        groupName: 'News',
        desc:
            'U.S. Market News component allows you to monitor and read intraday news on the U.S. Markets.'
    },
    {
        componentName: 'mwc-markets-report',
        componentType: 'mwc-markets-report',
        componentState: {
            layout: 'list',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Morningstar Report',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report',
        /* eslint-disable */
        desc:
            "Morningstar Stock Report Providing a curated composition of Morningstar's research, data, and visualizations to help investors decide whether it is a good time to buy or sell a stock. Morningstar's impartial and rigorous research is performed by a team of 97 analysts globally. Over 1,400 companies are covered by an analyst. The remainder of the stock universe is covered by a quantitative model that estimates the value a Morningstar® analyst would likely assign to the company.<br/><br/> Morningstar Fund Report helps investors assess the fund and its manager using Morningstar's own research, data, and visualizations. Morningstar's impartial and rigorous research is performed by a team of over 100 analysts. Our analysts provide qualitative research for 2,000+ mutual funds and 300+ exchange-traded funds in the US alone. This report is only available in a fund's base currency.<br/><br/>Our Index Report provides an easy one-page view of the key characteristics of a range of global and local indices. This report covers both Morningstar and 3rd party indices (subject to license agreements)."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-analysis-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'analysis',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Morningstar Analysis Report',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report',
        /* eslint-disable */
        desc:
            "Morningstar Analysis Report component contains Morningstar's unbiased opinion of the stock's prospects for the 500 largest and most important companies. Fund analysts closely monitor 2,000 mutual funds."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-profile-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'profile',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Company Profile',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        desc:
            'Company Profile component containing corporate office information and general business description of the company.'
    },
    {
        componentName: 'mwc-markets-dividends-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'dividends',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Corporate Actions',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        desc:
            'Corporate Actions component provides the financial well-being and shareholder value in terms of dollar amount each share receives and percent of the current market.'
    },
    {
        componentName: 'mwc-markets-executive-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'executive',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Executive Team',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        desc:
            'Executives Team component provides a detailed view of company executive and compensation information.'
    },
    {
        componentName: 'mwc-markets-financials-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'financials',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Fundamentals',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        /* eslint-disable */
        desc:
            "Fundamentals Component provides detail view of company's financial statements."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-operating-performance',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'performance',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Operating Performance',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        /* eslint-disable */
        desc:
            "Operating Performance component uses charts and ratios to depict how efficiently a company is using its resources to generate sales and increase shareholder value. Providing historical trends and movement of holding along with Morningstar proprietary data. Used to analyze effectiveness of companies' operations."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-ownership-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'ownership',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Ownership',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        desc:
            'Ownership component contains percentages of common shares owned by funds and current buy/sell activity, it provides a detailed view of key funds or institutions that own a stock in its portfolio.'
    },
    {
        componentName: 'mwc-markets-price-fairValue-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'priceFairvalue',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Price vs. Fair Value',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        /* eslint-disable */
        desc:
            "Morningstar signature Price to Fair Value chart is designed to help investors assess a company's shares using three key elements of our investment research. These elements provide investors not only with an up-to-date snapshot, but as Morningstar compares market prices to the Fair Value every day, we assemble a detailed, long-term perspective on our ratings and movements of the company's share price."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-trailingReturns-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'trailingReturns',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Trailing Returns',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        desc:
            'Trailing Returns component provides historical return of a stock with side-by-side comparison to the respective benchmark and sector. It broadly gives user an idea about whether the stock has created or depleted wealth over a specific time period.'
    },
    {
        componentName: 'mwc-markets-valuation-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            activeModule: 'valuation',
            type: 'premium',
            symbol: DEFAULT.STOCK_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Valuation',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Equity',
        /* eslint-disable */
        desc:
            "Valuation Component contains ratios and information used to determine the equity's value based on its earnings outlook, market value, and other financial metrics."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-fee-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'price',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Fee',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        desc:
            'Fee component is Fund and ETF specific that provides a detailed view of the fees associated with the investments.'
    },
    {
        componentName: 'mwc-markets-firm-profile-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'parent',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Firm Profile',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        desc:
            'Firm Profile component is Fund and ETF specific that provides a detailed view of fund flows and fund family details for the investment firm.'
    },
    {
        componentName: 'mwc-markets-fund-management-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'people',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Fund Management',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        desc:
            'Fund Management component provides a full view of the management team and their tenure with the investment firm. Detail view is available to show the total assets and list of funds managed by each manager.'
    },
    {
        componentName: 'mwc-markets-performance-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'performance',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Performance',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        desc:
            'Performance component provides historical return of a security with side-by-side comparison to the respective benchmark and peer group.'
    },
    {
        componentName: 'mwc-markets-portfolio',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'portfolio',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Portfolio',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        desc:
            'Portfolio component is Fund and ETF specific that provides a full view of portfolio details including Asset Allocation, Stock Style, Market Exposure, Financial Metrics, Sustainability & Carbon Metrics and Top investment holdings.'
    },
    {
        componentName: 'mwc-markets-risk-report',
        componentType: 'mwc-markets-report',
        componentState: {
            showNav: false,
            autoHeight: true,
            activeModule: 'risk',
            showAutocomplete: true,
            type: 'premium',
            symbol: DEFAULT.FUND_SYMBOL,
            salBundleHost: 'lib/'
        },
        title: 'Risk',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        groupName: 'Report - Fund & ETF',
        /* eslint-disable */
        desc:
            "Risk component is Fund and ETF specific that provides risk/return analysis based on assessment of variations of the fund's monthly returns in comparison to similar funds. The greater the variation, the larger the risk score. Funds with less than three years of performance history are not rated."
        /* eslint-enable */
    },
    {
        componentName: 'mwc-markets-qualitative-research',
        componentType: 'mwc-markets-qualitative-research',
        componentState: {
            autoHeight: true
        }
    },
    {
        componentName: 'mwc-markets-watchlist-canadian-bond',
        componentType: 'mwc-markets-watchlist',
        watchlistType: 'static',
        componentState: {
            showExcelRTD: true,
            defaultDataPoints: DATA_POINTS_2,
            gridHover: {
                menu: []
            },
            columnSets: INDEX_COLUMN_SETS,
            columnSetsBehaviour: DEFAULT.COLUMN_SET_BEHAVIOUR
        },
        isClosable: true,
        title: 'Canadian Bond Watchlist',
        groupName: 'Markets',
        showSymbol: false,
        showExportIcon: true,
        showImportIcon: true,
        showAutocomplete: false,
        desc:
            'Canadian Bond Watchlist allows you to access and analyze intraday and historical exchange market data for various maturities across the Canada Treasury Bond and Core Bond markets.'
    }
];

export default components;
