const globalSettings = {
    theme: 'default',
    dataType: 'pull',
    autoSave: true,
    autoSaveInterval: 5,
    notificationSound: 'off',
    notificationType: 'screenOnly',
    region: 'US',
    showVideo: true,
    functions: '',
    userGuide:
        'https://morningstardirect.morningstar.com/clientcomm/MarketMonitoring.pdf'
};

export default globalSettings;
