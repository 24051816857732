const workbooks = [
    {
        id: 'default-1',
        name: 'marketsOverview',
        profile: {
            isActive: true
        },
        activeWorksheetId: 'default-1',
        worksheets: [
            {
                id: 'default-1',
                name: 'Markets',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            activeItemIndex: 0,
                                            type: 'stack',
                                            width: 40,
                                            content: [
                                                {
                                                    type: 'component',
                                                    isClosable: true,
                                                    showAutocomplete: false,
                                                    watchlistType: 'static',
                                                    componentName:
                                                        'mwc-markets-watchlist-market-index'
                                                },
                                                {
                                                    type: 'component',
                                                    isClosable: true,
                                                    showAutocomplete: false,
                                                    watchlistType: 'static',
                                                    componentName:
                                                        'mwc-markets-watchlist-morningstar-index'
                                                },
                                                {
                                                    type: 'component',
                                                    isClosable: true,
                                                    showAutocomplete: true,
                                                    watchlistType: 'readonly',
                                                    componentName:
                                                        'mwc-markets-watchlist-portfolio-north-america'
                                                },
                                                {
                                                    type: 'component',
                                                    isClosable: true,
                                                    showAutocomplete: true,
                                                    watchlistType: 'readonly',
                                                    componentName:
                                                        'mwc-markets-watchlist-portfolio-emea'
                                                },
                                                {
                                                    type: 'component',
                                                    isClosable: true,
                                                    showAutocomplete: true,
                                                    watchlistType: 'readonly',
                                                    componentName:
                                                        'mwc-markets-watchlist-portfolio-asia'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-gla'
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-quote'
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-minichart'
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-investment'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-2',
                name: 'Chart',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    activeItemIndex: 0,
                                    type: 'stack',
                                    width: 40,
                                    content: [
                                        {
                                            type: 'component',
                                            isClosable: true,
                                            showAutocomplete: false,
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-market-index'
                                        },
                                        {
                                            type: 'component',
                                            isClosable: true,
                                            showAutocomplete: false,
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-morningstar-index'
                                        },
                                        {
                                            type: 'component',
                                            isClosable: true,
                                            showAutocomplete: true,
                                            watchlistType: 'readonly',
                                            componentName:
                                                'mwc-markets-watchlist-portfolio-north-america'
                                        },
                                        {
                                            type: 'component',
                                            isClosable: true,
                                            showAutocomplete: true,
                                            watchlistType: 'readonly',
                                            componentName:
                                                'mwc-markets-watchlist-portfolio-emea'
                                        },
                                        {
                                            type: 'component',
                                            isClosable: true,
                                            showAutocomplete: true,
                                            watchlistType: 'readonly',
                                            componentName:
                                                'mwc-markets-watchlist-portfolio-asia'
                                        }
                                    ]
                                },
                                {
                                    type: 'component',
                                    componentName: 'markets-components-svgchart'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-3',
                name: 'Technical Analysis',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-svgchart',
                                            componentSettings: {
                                                frequency: '1',
                                                intervalType: 'OneDay',
                                                intervalBreakPointMap: {
                                                    '600': [
                                                        '1D',
                                                        '3M',
                                                        '3Y',
                                                        '5Y',
                                                        'MAX'
                                                    ]
                                                },
                                                charts: {
                                                    mainChart: {
                                                        chartType: 'ohlcChart'
                                                    }
                                                },
                                                indicators: [
                                                    {
                                                        name: 'SMA',
                                                        parameters: [
                                                            10,
                                                            50,
                                                            100
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-svgchart',
                                            componentSettings: {
                                                intervalType: 'ThreeYear',
                                                frequency: 'w',
                                                intervalBreakPointMap: {
                                                    '600': [
                                                        '1D',
                                                        '3M',
                                                        '3Y',
                                                        '5Y',
                                                        'MAX'
                                                    ]
                                                },
                                                charts: {
                                                    mainChart: {
                                                        chartType: 'lineChart'
                                                    }
                                                },
                                                indicators: [
                                                    {
                                                        name: 'MACD',
                                                        parameters: [26, 12, 9]
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-svgchart',
                                            componentSettings: {
                                                frequency: 'd',
                                                intervalType: 'ThreeMonth',
                                                intervalBreakPointMap: {
                                                    '600': [
                                                        '1D',
                                                        '3M',
                                                        '3Y',
                                                        '5Y',
                                                        'MAX'
                                                    ]
                                                },
                                                events: ['dividend'],
                                                indicators: [
                                                    {
                                                        name: 'BBands',
                                                        parameters: [20, 2]
                                                    }
                                                ],
                                                charts: {
                                                    mainChart: {
                                                        chartType: 'abChart',
                                                        showDividend: true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-svgchart',
                                            componentSettings: {
                                                frequency: 'm',
                                                intervalType: 'FiveYear',
                                                intervalBreakPointMap: {
                                                    '600': [
                                                        '1D',
                                                        '3M',
                                                        '3Y',
                                                        '5Y',
                                                        'MAX'
                                                    ]
                                                },
                                                indicators: [
                                                    {
                                                        name: 'RSI',
                                                        parameters: [14]
                                                    }
                                                ],
                                                charts: {
                                                    mainChart: {
                                                        chartType:
                                                            'candlestickChart'
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-4',
                name: 'News',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-investment'
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-top-stories'
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-us-markets'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-5',
                name: 'Options',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'column',
                            content: [
                                {
                                    type: 'component',
                                    componentName: 'mwc-markets-options'
                                },
                                {
                                    type: 'component',
                                    componentName: 'markets-components-svgchart'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-6',
                name: 'Morningstar Profile',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-quote'
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'markets-components-svgchart'
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-investment'
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-profile-report'
                                        },
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-report'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            }
        ]
    }
];

export default workbooks;
